.badge-wrapper {
  display: flex;
  align-items: center;

  & .badge {
    margin-left: 8px;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  padding: 4px 6px;
  font-size: 1.625rem;
  line-height: 90%;
  color: $white;
  background: $course-hero-blue-500;
  border-radius: 20px;

  &.badge-disabled {
    color: $gray-500;
    background: $gray-400;
  }

  &.badge-success {
    background: $success-green;
  }

  &.badge-danger {
    background: $danger-red;
  }
}
