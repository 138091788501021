.tabs {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: inset 0 -2px 0 $gray-400;
  -webkit-overflow-scrolling: touch;
  @include list-unstyled();

  .tab {
    display: flex;
    align-items: stretch;
    justify-content: center;
    max-width: 200px;
    padding: 0 4rem;
    font-weight: 400;
    line-height: 3;
    color: $gray-800;
    cursor: pointer;
    border-bottom: .25rem solid transparent;
    outline-offset: -4px;

    &:hover {
      font-weight: 400;
      color: $course-hero-magenta-500;
    }

    &.active {
      font-weight: 500;
      color: $course-hero-blue-500;
      border-bottom-color: $course-hero-blue-500;
    }

    &.disabled,
    &.disabled:hover {
      font-weight: 400;
      color: $gray-500;
      cursor: not-allowed;
      border-bottom-color: transparent;
    }
  }

  &.tabs-fitted {
    .tab {
      flex: 1;
      flex-basis: 0;
      max-width: none;
    }
  }

  &.tabs-scrollable {
    overflow-x: auto;

    .tab {
      flex-basis: auto;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  &.tabs-extended {
    .tab {
      flex-direction: column;
      max-width: none;
      padding: 1.6rem 4rem;
      line-height: initial;
      color: $gray-900;
      text-align: left;

      .tab-title {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-800;
      }

      .tab-subtitle {
        font-size: 1.75rem;
        font-weight: 400;
        line-height: 2;
        color: $gray-700;
      }
    }

    .tab:hover {
      .tab-title {
        font-weight: 400;
        color: $course-hero-magenta-500;
      }

      .tab-subtitle {
        font-weight: 400;
        color: $gray-800;
      }
    }

    .active,
    .active:hover {
      .tab-title {
        font-weight: 500;
        color: $course-hero-blue-500;
      }

      .tab-subtitle {
        font-weight: 400;
        color: $gray-800;
      }
    }

    .disabled,
    .disabled:hover {
      .tab-title,
      .tab-subtitle {
        font-weight: 400;
        color: $gray-500;
      }
    }
  }

  // Modifiers
  &.tabs-centered {
    justify-content: center;
  }

  &.tabs-split-equally {
    .tab {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &.tabs-right {
    justify-content: flex-end;
  }
}
