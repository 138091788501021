// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

@include media-breakpoint-up(lg) {
  h1,
  .h1 {
    font-size: $h1-font-size-desktop;
    line-height: $h1-line-height-desktop;
  }

  h2,
  .h2 {
    font-size: $h2-font-size-desktop;
    line-height: $h2-line-height-desktop;
  }

  h3,
  .h3 {
    font-size: $h3-font-size-desktop;
    line-height: $h3-line-height-desktop;
  }

  h4,
  .h4 {
    font-size: $h4-font-size-desktop;
    line-height: $h4-line-height-desktop;
  }

  h5,
  .h5 {
    font-size: $h5-font-size-desktop;
    line-height: $h5-line-height-desktop;
  }

  h6,
  .h6 {
    font-size: $h6-font-size-desktop;
    line-height: $h6-line-height-desktop;
  }
}

@include media-breakpoint-between(md, lg) {
  h1,
  .h1 {
    font-size: $h1-font-size-tablet;
    line-height: $h1-line-height-tablet;
  }

  h2,
  .h2 {
    font-size: $h2-font-size-tablet;
    line-height: $h2-line-height-tablet;
  }

  h3,
  .h3 {
    font-size: $h3-font-size-tablet;
    line-height: $h3-line-height-tablet;
  }

  h4,
  .h4 {
    font-size: $h4-font-size-tablet;
    line-height: $h4-line-height-tablet;
  }

  h5,
  .h5 {
    font-size: $h5-font-size-tablet;
    line-height: $h5-line-height-tablet;
  }

  h6,
  .h6 {
    font-size: $h6-font-size-tablet;
    line-height: $h6-line-height-tablet;
  }
}

@include media-breakpoint-down(md) {
  h1,
  .h1 {
    font-size: $h1-font-size-mobile;
    line-height: $h1-line-height-mobile;
  }

  h2,
  .h2 {
    font-size: $h2-font-size-mobile;
    line-height: $h2-line-height-mobile;
  }

  h3,
  .h3 {
    font-size: $h3-font-size-mobile;
    line-height: $h3-line-height-mobile;
  }

  h4,
  .h4 {
    font-size: $h4-font-size-mobile;
    line-height: $h4-line-height-mobile;
  }

  h5,
  .h5 {
    font-size: $h5-font-size-mobile;
    line-height: $h5-line-height-mobile;
  }

  h6,
  .h6 {
    font-size: $h6-font-size-mobile;
    line-height: $h6-line-height-mobile;
  }
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

// Hero text is larger than headings

@include media-breakpoint-up(lg) {
  .hero-1 {
    font-size: $hero-1-font-size-desktop;
    line-height: $hero-1-line-height-desktop;
  }

  .hero-2 {
    font-size: $hero-2-font-size-desktop;
    line-height: $hero-2-line-height-desktop;
  }

  .hero-3 {
    font-size: $hero-3-font-size-desktop;
    line-height: $hero-3-line-height-desktop;
  }
}

@include media-breakpoint-between(md, lg) {
  .hero-1 {
    font-size: $hero-1-font-size-tablet;
    line-height: $hero-1-line-height-tablet;
  }

  .hero-2 {
    font-size: $hero-2-font-size-tablet;
    line-height: $hero-2-line-height-tablet;
  }

  .hero-3 {
    font-size: $hero-3-font-size-tablet;
    line-height: $hero-3-line-height-tablet;
  }
}

@include media-breakpoint-down(md) {
  .hero-1 {
    font-size: $hero-1-font-size-mobile;
    line-height: $hero-1-line-height-mobile;
  }

  .hero-2 {
    font-size: $hero-2-font-size-mobile;
    line-height: $hero-2-line-height-mobile;
  }

  .hero-3 {
    font-size: $hero-3-font-size-mobile;
    line-height: $hero-3-line-height-mobile;
  }
}

.hero-1,
.hero-2,
.hero-3 {
  font-weight: $font-weight-bold;
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
  line-height: $small-line-height;
}

.legal {
  font-size: $legal-font-size;
  line-height: $legal-line-height;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}

.caption {
  font-size: $caption-font-size;
  font-weight: $font-weight-bold;
}

@include media-breakpoint-up(lg) {
  .caption {
    line-height: $caption-line-height-desktop;
  }
}

@include media-breakpoint-between(md, lg) {
  .caption {
    line-height: $caption-line-height-tablet;
  }
}

@include media-breakpoint-down(md) {
  .caption {
    line-height: $caption-line-height-mobile;
  }
}

// Body font sizes
.body-1 {
  font-size: $font-size-base;
}

.body-2 {
  font-size: $font-size-body-2;
}

.font-force-secondary {
  font-family: $font-family-secondary-only;
}
