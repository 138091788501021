.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  ul.pages {
    display: flex;
    margin: 0;
    @include list-unstyled();

    @include media-breakpoint-down(sm) {
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .page {
    margin: $pagination-margin-y $pagination-margin-x;

    @include media-breakpoint-down(sm) {
      margin: $pagination-margin-y $pagination-margin-x-sm;

      &:first-of-type {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        margin-left: 0;

        .page-link {
          padding: 0;
        }
      }

      &:last-of-type {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        margin-right: 0;

        .page-link {
          padding: 0;
        }
      }
    }

    .page-link.active {
      z-index: 1;
      font-weight: $font-weight-bold;
      color: $pagination-active-color;
      border-bottom: $pagination-active-border;
    }

    .page-link.disabled {
      color: $pagination-disabled-color;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .page-link {
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    font-weight: $font-weight-medium;
    color: $pagination-color;
    border-bottom: $pagination-border;

    @include media-breakpoint-down(sm) {
      padding: $pagination-padding-y;
    }

    &:visited {
      color: $pagination-color;
    }

    &:hover {
      z-index: 2;
      color: $pagination-active-color;
      text-decoration: none;
    }

    &:focus {
      z-index: 2;
      outline: $pagination-focus-outline;
      box-shadow: $pagination-focus-box-shadow;
    }

    i[class^="chi-pointer-"] {
      font-size: 1.7rem;
      font-weight: 800;
    }
  }

  .page-prev {
    margin-left: 1rem;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .page-next {
    margin-right: 1rem;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .page-more {
    position: relative;
    display: block;
    padding: $pagination-padding-y 0;
    font-weight: $font-weight-medium;
    color: $pagination-active-color;
    border-bottom: $pagination-border;
  }
}
