.card {
  position: relative;
  display: flex;
  @include border-radius($border-radius);
  box-shadow: $box-shadow-all;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .card-body {
    flex: 1;
    flex-basis: 25%;
    padding: 3rem;
  }

  .card-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  .card-subtitle {
    margin-top: .4rem;
    color: $gray-800;
  }

  .card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    color: $white;
    background: rgba(0, 0, 0, .7);
    @include border-radius(0 0 $border-radius $border-radius);
  }

  .card-image {
    flex: 1;

    @include media-breakpoint-up(md) {
      flex: none;
      height: 168px;
    }

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.card-image-top {
      @include border-radius($border-radius $border-radius 0 0);
    }

    &.card-image-bottom {
      @include border-radius(0 0 $border-radius $border-radius);
    }

    &.card-image-full {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      @include border-radius($border-radius);
    }
  }
}
