// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace {
  font-family: $font-family-monospace;
}

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-medium { font-weight: $font-weight-medium !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }

// Exact font weights

@for $i from 1 through 1000 {
  @if $i % 100 == 0 {
    .font-weight-#{$i} {
      font-weight: #{$i} !important;
    }
  }
}

// Contextual colors

.text-white { color: $white !important; }

@each $swatch, $map in $colors {
  @each $shade, $hex in $map {
    @include text-emphasis-variant(".text-#{$swatch}-#{$shade}", $hex);
  }
}

.text-body { color: $body-color !important; }
.text-muted { color: $text-muted !important; }

// Decoration

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}
