//
// Basic Bootstrap table
//

.table {
  width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.
  border: $table-border-width solid $table-border-color;

  th,
  td {
    padding: $table-cell-padding;
    font-weight: $table-body-font-weight;
    line-height: $table-cell-line-height;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th {
      font-weight: $table-head-font-weight;
      vertical-align: bottom;
    }

    th,
    td {
      border-bottom-color: $gray-500;
      border-bottom-width: (2 * $table-border-width);
    }

    th.double-header {
      font-weight: $table-colgroup-font-weight;
      text-align: center;
      border-bottom: $table-border-width solid $table-border-color;
    }
  }

  tbody {
    th[scope="colgroup"] {
      font-weight: $table-head-font-weight;
      text-align: center;
      background-color: $table-head-bg;
    }
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }

  .table {
    background-color: $body-bg;
  }

  caption {
    font-size: 1.75rem;
    line-height: $table-cell-line-height;
    color: $table-caption-color;
  }
}


//
// Condensed table w/less padding
//

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}


//
// Expanded table w/more padding
//

.table-lg {
  th,
  td {
    padding: $table-cell-padding-lg;
  }
}


// Remove borders all around the table and between all the columns.
.table-borderless {
  &.table,
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-non-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);


// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}
