.prompt {
  position: relative;
  padding: 2.5rem 3.75rem 2.5rem 10rem;
  background: $white;
  box-shadow: $box-shadow-all;
  @include border-radius($border-radius);

  .prompt-heading {
    margin-left: 1rem;
    font-size: $h6-font-size-desktop;
    font-weight: $font-weight-bold;
  }

  .prompt-close {
    position: absolute;
    top: 50%;
    right: 3rem;
    font-size: $h6-font-size-desktop;
    color: $gray-900;
    cursor: pointer;
    transform: translateY(-50%);
  }

  .prompt-cta {
    position: absolute;
    top: 50%;
    right: 8.25rem;
    transform: translateY(-50%);
  }

  p {
    margin: 1rem 0 0 1rem;
  }

  .prompt-icon {
    position: absolute;
    top: 50%;
    left: 3rem;
    font-family: $font-family-icon;
    font-size: $h3-font-size-desktop;
    transform: translateY(-50%);
  }

  &.prompt-dark {
    color: $white;
    background: $gray-900;

    .prompt-close {
      color: $white;
    }
  }

  &.prompt-floater {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    width: 100%;
    color: $white;
    background: $course-hero-blue-500;
    @include border-radius(0);
    box-shadow: none;

    &::before {
      display: none;
    }

    &.prompt-floater-top {
      top: 0;
      bottom: auto;
    }

    .prompt-icon {
      color: $white;
    }

    .prompt-close {
      color: $white;
    }

    &.prompt-success {
      background: $success-green;
    }

    &.prompt-warning {
      color: $gray-900;
      background: $warning-yellow;

      .prompt-icon,
      .prompt-close {
        color: $gray-900;
      }
    }

    &.prompt-danger {
      background: $danger-red;
    }

    &.prompt-snackbar {
      bottom: 3rem;
      left: 3rem;
      width: auto;
      @include border-radius($border-radius);
    }

    @include media-breakpoint-down(sm) {
      &.prompt-snackbar {
        left: 50%;
        width: 95%;
        transform: translate(-50%, 0);
      }
    }
  }

  &.prompt-banner {
    padding-left: 3rem;
    box-shadow: none;
    @include border-radius(0);

    &::before {
      display: none;
    }

    .prompt-banner-text {
      max-width: 50%;
      font-weight: $font-weight-bold;
    }

    &.prompt-success {
      background: $success-green;
    }

    &.prompt-warning {
      color: $gray-900;
      background: $warning-yellow;

      .prompt-close {
        color: $gray-900;
      }
    }

    &.prompt-danger {
      color: $white;
      background: $danger-red;

      .prompt-close,
      .prompt-cta {
        color: $white;
      }
    }
  }
}
