%ddi-item {
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  text-align: left;
  border: none;

  &:first-child {
    padding-top: $input-padding-y + .0625rem;
  }
}

.ddi-wrapper {
  position: relative;
}
.ddi-inline {
  display: inline-block;
}

%ddi-input-style {
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
}


.ddi-input {
  @extend %ddi-input-style;

  @include ddi-bg($custom-select-bg, $custom-select-indicator);
  @include ddi-validation-styles("valid", $form-feedback-valid-color);
  @include ddi-validation-styles("invalid", $form-feedback-invalid-color);

  width: 100%;
  cursor: default;

  &:disabled {
    @include ddi-bg($input-disabled-bg, $custom-select-indicator);
  }

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
}

.ddi-input-btn {
  @extend %ddi-input-style;

  background: $custom-select-bg;

  &[aria-expanded="true"] {
    background: map-get($neutrals, "400");
  }

  &.btn-link {
    display: inline-flex;
    padding: 0;
    font-size: inherit;
    font-weight: $font-weight-normal;
    background: transparent;
    border: none;

    &::after {
      display: inline-block;
      width: 1rem;
      height: .75rem;
      margin: .625rem 0 0 1rem;
      content: "";
      background: transparent $custom-select-indicator no-repeat right 0 top 0;
    }
  }

  & i {
    line-height: $input-line-height;
  }
}

.dropdown-menu {
  .ddi-wrapper & {
    @include ddi-bg($input-bg, $custom-select-indicator-inverted);
    top: 0;
    width: 100%;

    min-width: 25rem;
    margin: -.0625rem 0 0;
    border: $input-border-width solid map-get($primaries, "500");
    border-radius: $input-border-radius;
  }
  .ddi-input-btn ~ & {
    margin: ($input-padding-y + $input-border-width) * 2 + $input-line-height + 1rem 0 0;
    background: $input-bg;
  }
  .ddi-multi & {
    top: auto;
    margin: -.125rem 0 0;
    border-top: $input-border-width solid $input-border-color;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .btn-link ~ & {
    margin: $input-line-height + 1rem 0 0;
  }
}

.dropdown-item {
  .ddi-wrapper & {
    @extend %ddi-item;
    background: $input-bg;

    &:hover {
      color: $white;
      background: map-get($primaries, "500");
    }
  }

  &.dropdown-item-placeholder {
    color: $input-placeholder-color;

    &:hover {
      color: $input-placeholder-color;
      background: transparent;
    }
  }
}
.ddi-category {
  position: relative;
  border-bottom: 1px solid $dropdown-divider-bg;

  .dropdown-item-placeholder + & {
    border-top: 1px solid $dropdown-divider-bg;
  }
  &:last-child {
    border-bottom: none;
  }
}
.ddi-category-search-icon {
  position: absolute;
  top: 1.5rem;
  left: 1.75rem;
  display: block;
  font-size: 2.5rem;
  color: map-get($secondaries, "500");
}
.ddi-category-search {
  padding-left: 5rem;
  border: none;
}

.ddi-category-option {
  @extend %ddi-item;

  background: $input-bg;

  &:hover {
    color: $white;
    background: map-get($primaries, "500");
  }
}

.ddi-item-icon {
  padding: 0 2rem 0 0;
  font-size: 2rem;
}
.ddi-item-icon-right {
  position: absolute;
  right: 0;
}

.ddi-multi-selected-list {
  @extend %ddi-input-style;

  @include ddi-bg($custom-select-bg, $custom-select-indicator);
  @include ddi-validation-styles("valid", $form-feedback-valid-color);
  @include ddi-validation-styles("invalid", $form-feedback-invalid-color);

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: $ddi-multi-list-padding $ddi-multi-list-padding 0;
  margin: 0;
  list-style-type: none;
  background-position-y: $ddi-multi-height / 2 - .375rem;

  &[aria-expanded="true"] {
    @include ddi-bg($custom-select-bg, $custom-select-indicator-inverted);
    background-position-y: $ddi-multi-height / 2 - .375rem;
    border: $input-border-width solid map-get($primaries, "500");
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.ddi-multi-menu-selected-list {
  display: flex;
  flex-wrap: wrap;
  padding: $ddi-multi-list-padding $ddi-multi-list-padding 0;
}
.ddi-multi-selected-list-item {
  display: inline-flex;
  align-items: center;
  padding: $ddi-multi-list-item-padding-y $ddi-multi-list-item-padding-x;
  margin: 0 1rem $ddi-multi-list-padding 0;
  font-size: $ddi-multi-list-line-height;
  line-height: $ddi-multi-list-line-height;
  color: map-get($neutrals, "600");
  background: map-get($neutrals, "300");
  border-radius: $border-radius;
}
.ddi-multi-selected-remove {
  padding: 0;
  margin: 0 0 0 1rem;
  color: map-get($neutrals, "500");
  cursor: pointer;
  background: transparent;
  border: 0;
}
