.label {
  display: inline-block;
  padding: .5rem 1.25rem;
  font-size: 1.625rem;
  font-weight: $font-weight-medium;
  text-align: center;
  background: $gray-500;
  @include border-radius($border-radius);

  &.label-primary,
  &.label-success,
  &.label-danger {
    color: $white;
  }

  &.label-archived {
    color: $gray-500;
    background: $gray-400;
  }

  &.label-primary {
    background: $course-hero-blue-500;
  }

  &.label-success {
    background: $success-green;
  }

  &.label-warning {
    background: $warning-yellow;
  }

  &.label-danger {
    background: $danger-red;
  }
}
