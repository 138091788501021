.panel {
  background: $white;
  @include border-radius($border-radius);

  &.panel-bordered {
    border: $gray-400 1px solid;
  }

  &.panel-elevated {
    box-shadow: 0 0 1.25rem .25rem rgba($black, .1);
  }

  div.panel-divider {
    border-top: $gray-400 1px solid;

    &.panel-divider-light {
      border-top-color: $gray-300;
    }
  }
}
