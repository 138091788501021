// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    color: $custom-select-disabled-color;
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

select.form-control {
  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
  margin-bottom: 0; // Override the `<label>/<legend>` default
  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
}

// Required field indicator
.required {
  color: $form-feedback-invalid-color;
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.

.form-control-sm {
  height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.form-control-lg {
  height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

// stylelint-disable no-duplicate-selectors
select.form-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.form-control {
  height: auto;
  min-height: ($input-line-height * 3) + ($input-padding-y + $input-border-width ) * 2;
}

// stylelint-enable no-duplicate-selectors

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}


// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}


// Checkboxes and radios
//
// Uses pseudo-elements to create custom checkbox / radio styles

.form-check {
  position: relative;
  display: block;
}

.form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

// checkbox / radio circle
%checkbox {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: $form-check-size;
  height: $form-check-size;
  cursor: pointer;
  content: "";
  background: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  transition: all .2s linear;

  :disabled ~ &::before {
    background-color: $input-disabled-bg;
  }

  .form-check:hover :enabled:not(:checked) ~ &::before {
    border: 1px solid $input-focus-border-color;
  }
}

.form-check-label {
  padding-left: $form-check-input-gutter;
  margin-bottom: 0; // Override default `<label>` bottom margin

  :disabled ~ & {
    color: $text-muted;
  }

  // handle psuedo-elements for box (checkbox) and circle (radio button)
  [type="checkbox"] ~ &::before {
    @extend %checkbox;
  }

  [type="checkbox"]:checked:not(:disabled) ~ &::before {
    background-color: $input-focus-border-color;
  }

  [type="checkbox"]:focus ~ &::before {
    box-shadow: $custom-control-focus-box-shadow;
  }

  // checkboxes - check
  [type="checkbox"] ~ &::after {
    position: absolute;
    top: .125rem;
    left: .5rem;
    display: block;
    font-family: ch-icon, sans-serif;
    font-size: 1.5rem;
    // in px because of an IE11 bug with psuedo-elements and line-height
    line-height: 19px;
    color: #fff;
    content: "\c237";
  }
  :disabled ~ &::after {
    color: $gray-600;
  }
  :disabled:not(:checked) ~ &::after {
    display: none;
  }

  // radio buttons - circle
  [type="radio"] ~ &::before {
    @extend %checkbox;
    border-radius: $form-check-size;
  }

  [type="radio"]:checked:not(:disabled) ~ &::before {
    border: 6px solid $input-focus-border-color;
  }

  [type="radio"]:focus ~ &::before {
    box-shadow: $custom-control-focus-box-shadow;
  }
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;
}

// Toggle button
//
// A special styled checkbox to create the appearance of a toggle

.form-toggle-text-label {
  display: block;
  font-weight: 500;
}

/* The switch - the box around the slider */
.form-toggle-wrapper {
  position: relative;
  display: inline-block;
  width: $form-toggle-width;
  height: $form-toggle-height;
}

/* Hide default HTML checkbox */
.form-toggle-wrapper input {
  width: 0;
  height: 0;
  opacity: 0;
}

/* The slider */
.form-toggle-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  border-radius: $form-toggle-border-radius;
  transition: $form-toggle-transition-speed;

  input:checked + & {
    background-color: $form-toggle-active-color;
  }

  input:disabled + & {
    opacity: $form-toggle-disabled-opacity;
  }

  input:focus + & {
    box-shadow: $custom-control-focus-box-shadow;
  }
}

// The dot in the slider
.form-toggle-slider::before {
  position: absolute;
  bottom: $form-toggle-dot-position;
  left: $form-toggle-dot-position;
  width: $form-toggle-dot-size;
  height: $form-toggle-dot-size;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: $form-toggle-transition-speed;

  input:checked + & {
    transform: translateX($form-toggle-active-position);
  }
}

/// Selects

.form-select {
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  line-height: $custom-select-line-height;
  color: $custom-select-color;
  vertical-align: middle;
  background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
  background-size: $custom-select-bg-size;
  border: $custom-select-border-width solid $custom-select-border-color;
  @if $enable-rounded {
    border-radius: $custom-select-border-radius;
  } @else {
    border-radius: 0;
  }
  @include box-shadow($custom-select-box-shadow);
  @include appearance(none);

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $custom-select-box-shadow, $custom-select-focus-box-shadow;
    } @else {
      box-shadow: $custom-select-focus-box-shadow;
    }

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    opacity: 0;
  }
}

.custom-select-sm {
  height: $custom-select-height-sm;
  padding-top: $custom-select-padding-y;
  padding-bottom: $custom-select-padding-y;
  font-size: $custom-select-font-size-sm;
}

.custom-select-lg {
  height: $custom-select-height-lg;
  padding-top: $custom-select-padding-y;
  padding-bottom: $custom-select-padding-y;
  font-size: $custom-select-font-size-lg;
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via .was-valid class on a parent and `:invalid` and `:valid`
// pseudo-classes on the input. Also includes direct styling of inputs with `.is-invalid` and `.is-valid`
// classes for server side validation.

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);
.was-validated :invalid,
.is-invalid {
  padding-right: $input-padding-x * 3;
}
.feedback-icon {
  @include form-invalid-icon-position($input-padding-y, $input-padding-x, $input-line-height, 2rem);

  position: relative;
  float: right;
  width: auto;

  .form-control-sm ~ & {
    @include form-invalid-icon-position($input-padding-y-sm, $input-padding-x-sm, $input-line-height-sm, $font-size-sm);
  }

  .form-control-lg ~ & {
    @include form-invalid-icon-position($input-padding-y-lg, $input-padding-x-lg, $input-line-height-lg, $font-size-lg);
  }

  .textarea-with-icon & {
    position: absolute;
    top: $input-padding-y;
    bottom: auto;
  }
}
.textarea-with-icon {
  position: relative;
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-right: $form-check-inline-margin-x;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
}
