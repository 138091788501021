@mixin ddi-bg ($bg, $indicator) {
  background: $bg $indicator no-repeat right $custom-select-padding-x top $ddi-indicator-y-offset;
  background-size: $custom-select-bg-size;
}
@mixin ddi-validation-styles($state, $color) {
  .was-validated &:#{$state},
  .is-#{$state} & {
    border-color: $color;

    &:focus {
      border-color: $color;
      box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
    }
  }
}
