.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 3rem;
  font-size: 2rem;
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  user-select: none;
  @include border-radius($border-radius);
  @include transition(all .1s ease-in);

  // Buttons with icons

  i {
    margin-left: 2rem;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  // Buttons types

  &.btn-primary {
    color: $white;
    background: $course-hero-magenta-500;
    border: $course-hero-magenta-500 1px solid;

    &:hover,
    &:focus {
      background: $course-hero-magenta-700;
    }

    &:active,
    &.highlighted {
      background: $course-hero-magenta-900;
    }

    &.disabled,
    &:disabled {
      color: $gray-500;
      background: $gray-300;
      border: $gray-300 1px solid;
    }
  }

  &.btn-secondary {
    color: $course-hero-blue-500;
    background: $white;
    border: $course-hero-blue-500 1px solid;

    &:hover,
    &:focus {
      color: $white;
      background: $course-hero-blue-a700;
      border: $course-hero-blue-a700 1px solid;
    }

    &:active,
    &.highlighted {
      color: $white;
      background: $course-hero-blue-500;
      border: $course-hero-blue-500 1px solid;
    }

    &.disabled,
    &:disabled {
      color: $gray-600;
      background: $white;
      border: $gray-300 1px solid;
    }
  }

  &.btn-tertiary {
    color: $course-hero-blue-500;
    background: transparent;
    border: none;

    &:hover,
    &:focus,
    &:active,
    &.highlighted {
      background: transparent;
    }

    &:hover,
    &:focus {
      color: $course-hero-blue-a700;
    }

    &:active,
    &.highlighted {
      color: $course-hero-blue-500;
    }

    &.disabled,
    &:disabled {
      color: $gray-500;
    }
  }

  &.btn-icon {
    padding: 1.5rem;

    i {
      margin-left: 0;
    }
  }

  &.btn-link {
    padding: 0;
    font-size: inherit;
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    border: none;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }

  // Modifiers

  &.btn-sm {
    padding: 1rem 2rem;
    font-size: 1.75rem;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }
}
