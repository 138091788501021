.accordion {
  .accordion-card {
    display: block;
    @include border-radius($border-radius);
    box-shadow: $box-shadow-all;

    .card-header {
      padding: 3rem;

      @include media-breakpoint-down(sm) {
        padding: 2rem;
      }

      &:first-child {
        @include border-radius(0 0 $border-radius $border-radius);
      }
    }

    .card-body {
      padding: 0 3rem 3rem;

      @include media-breakpoint-down(sm) {
        padding: 0 2rem 2rem;
      }
    }

    + .accordion-card {
      margin-top: 2rem;
    }
  }

  .btn.btn-link {
    justify-content: initial;
    width: 100%;
    padding: 0 4rem 0 0;
    font-weight: 500;
    color: $gray-900;
    text-align: left;

    &:hover {
      color: $course-hero-blue-a700;

      &::after,
      &.collapsed::after {
        color: $course-hero-blue-a700;
      }
    }

    &::after {
      position: absolute;
      right: 0;
      font-family: ch-icon, sans-serif;
      color: $gray-900;
      content: "\c1ef";
    }

    &.collapsed::after {
      position: absolute;
      right: 0;
      font-family: ch-icon, sans-serif;
      color: $gray-900;
      content: "\c1f1";
    }
  }
}
