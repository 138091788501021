// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

@mixin display-form-validation($state) {
  .was-validated :#{$state} ~ &,
  .is-#{$state} ~ & {
    display: block;
  }
}

@mixin generic-input-validation-styles($color) {
  border-color: $color;

  &:focus {
    border-color: $color;
    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
  }
}

@mixin form-validation-selectors($state) {
  .was-validated &:#{$state},
  &.is-#{$state} {
    @content;
  }
}

@mixin form-validation-state($state, $color) {
  .#{$state}-feedback {
    @include display-form-validation($state);

    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    font-size: $form-feedback-font-size;
    color: $color;
  }

  .#{$state}-tooltip {
    @include border-radius($tooltip-border-radius);
    @include display-form-validation($state);

    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $tooltip-padding-y $tooltip-padding-x;
    margin-top: .1rem;
    font-size: $tooltip-font-size;
    line-height: $line-height-base;
    color: color-yiq($color);
    background-color: rgba($color, $tooltip-opacity);
  }

  .form-control,
  .custom-select {
    @include form-validation-selectors($state) {
      @include generic-input-validation-styles($color);
    }
  }

  .form-check-input {
    @include form-validation-selectors($state) {
      ~ .form-check-label {
        color: $color;
      }
    }
  }

  .custom-control-input {
    @include form-validation-selectors($state) {
      ~ .custom-control-label {
        color: $color;

        &::before {
          background-color: lighten($color, 25%);
        }
      }

      &:checked {
        ~ .custom-control-label::before {
          @include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }
  }

  // custom file
  .custom-file-input {
    @include form-validation-selectors($state) {
      ~ .custom-file-label {
        border-color: $color;

        &::after { border-color: inherit; }
      }

      &:focus {
        ~ .custom-file-label {
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }
  }
}

@mixin form-invalid-icon-position($padding-y, $padding-x, $line-height, $font-size) {
  right: $padding-x;
  bottom: $line-height + $padding-y;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin appearance($value) {
  // CSS lint chokes at vendor prefixes, but are carefully packaged within a mixin for use in styling form elements
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
  /* stylelint-enable */
}
