/*
* Granite
* Copyright 2018-2019 Course Hero
*/
/*
* Bootstrap v4.1.3 (https://getbootstrap.com/)
* Copyright 2011-2018 The Bootstrap Authors
* Copyright 2011-2018 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
:root {
  --primary: #001a96;
  --secondary: #e00099;
  --ink: #002de4;
  --success: #30a04d;
  --warning: #ffc334;
  --danger: #c82914;
  --light: #fff;
  --dark: #16192b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 960px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Haas Grot Text Web", "Helvetica Neue", helvetica, arial, sans-serif;
  --font-family-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 8px;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(22, 25, 43, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Haas Grot Text Web", "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  color: #16192b;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ul,
ol {
  padding: 0 0 0 5rem;
  margin: 0 0 2rem 0;
}

ul li,
ol li {
  padding: .5rem 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: .5rem 0 0 0;
}

dl {
  margin-top: 0;
  margin-bottom: 2rem;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #002de4;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:visited {
  color: #001471;
}

a:hover {
  color: #e00099;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #878c91;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.875rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

iframe {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

@media (min-width: 960px) {
  h1,
  .h1 {
    font-size: 4.75rem;
    line-height: 6.375rem;
  }
  h2,
  .h2 {
    font-size: 3.75rem;
    line-height: 5.25rem;
  }
  h3,
  .h3 {
    font-size: 3.375rem;
    line-height: 5rem;
  }
  h4,
  .h4 {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  h5,
  .h5 {
    font-size: 2.625rem;
    line-height: 4rem;
  }
  h6,
  .h6 {
    font-size: 2.25rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  h1,
  .h1 {
    font-size: 4.25rem;
    line-height: 5.625rem;
  }
  h2,
  .h2 {
    font-size: 3.75rem;
    line-height: 5.25rem;
  }
  h3,
  .h3 {
    font-size: 3.375rem;
    line-height: 5rem;
  }
  h4,
  .h4 {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  h5,
  .h5 {
    font-size: 2.625rem;
    line-height: 4rem;
  }
  h6,
  .h6 {
    font-size: 2.25rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 959.98px) {
  h1,
  .h1 {
    font-size: 3.75rem;
    line-height: 5rem;
  }
  h2,
  .h2 {
    font-size: 3.375rem;
    line-height: 4.75rem;
  }
  h3,
  .h3 {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  h4,
  .h4 {
    font-size: 2.625rem;
    line-height: 4rem;
  }
  h5,
  .h5 {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }
  h6,
  .h6 {
    font-size: 2.25rem;
    line-height: 3.5rem;
  }
}

.lead {
  font-size: 2.34375rem;
  font-weight: 300;
}

@media (min-width: 960px) {
  .hero-1 {
    font-size: 7.5rem;
    line-height: 10rem;
  }
  .hero-2 {
    font-size: 6.75rem;
    line-height: 9rem;
  }
  .hero-3 {
    font-size: 6rem;
    line-height: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .hero-1 {
    font-size: 6.75rem;
    line-height: 9rem;
  }
  .hero-2 {
    font-size: 6rem;
    line-height: 8rem;
  }
  .hero-3 {
    font-size: 4.75rem;
    line-height: 6.375rem;
  }
}

@media (max-width: 959.98px) {
  .hero-1 {
    font-size: 6rem;
    line-height: 8rem;
  }
  .hero-2 {
    font-size: 4.75rem;
    line-height: 6.375rem;
  }
  .hero-3 {
    font-size: 4.25rem;
    line-height: 5.625rem;
  }
}

.hero-1,
.hero-2,
.hero-3 {
  font-weight: 700;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 0.125rem solid rgba(22, 25, 43, 0.1);
}

small,
.small {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 2.625rem;
}

.legal {
  font-size: 1.375rem;
  line-height: 2.25rem;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 2.34375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #dde0e3;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.caption {
  font-size: 2rem;
  font-weight: 700;
}

@media (min-width: 960px) {
  .caption {
    line-height: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .caption {
    line-height: 3.5rem;
  }
}

@media (max-width: 959.98px) {
  .caption {
    line-height: 3.5rem;
  }
}

.body-1 {
  font-size: 1.875rem;
}

.body-2 {
  font-size: 1.75rem;
}

.font-force-secondary {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-fluid {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 576px) {
  .row {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
}

@media (max-width: 767.98px) {
  .row {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media (min-width: 576px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 960px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.accordion .accordion-card {
  display: block;
  border-radius: 0.375rem;
  box-shadow: 0 0 10px #dde0e3;
}

.accordion .accordion-card .card-header {
  padding: 3rem;
}

@media (max-width: 767.98px) {
  .accordion .accordion-card .card-header {
    padding: 2rem;
  }
}

.accordion .accordion-card .card-header:first-child {
  border-radius: 0 0 0.375rem 0.375rem;
}

.accordion .accordion-card .card-body {
  padding: 0 3rem 3rem;
}

@media (max-width: 767.98px) {
  .accordion .accordion-card .card-body {
    padding: 0 2rem 2rem;
  }
}

.accordion .accordion-card + .accordion-card {
  margin-top: 2rem;
}

.accordion .btn.btn-link {
  justify-content: initial;
  width: 100%;
  padding: 0 4rem 0 0;
  font-weight: 500;
  color: #16192b;
  text-align: left;
}

.accordion .btn.btn-link:hover {
  color: #002de4;
}

.accordion .btn.btn-link:hover::after, .accordion .btn.btn-link:hover.collapsed::after {
  color: #002de4;
}

.accordion .btn.btn-link::after {
  position: absolute;
  right: 0;
  font-family: ch-icon, sans-serif;
  color: #16192b;
  content: "\c1ef";
}

.accordion .btn.btn-link.collapsed::after {
  position: absolute;
  right: 0;
  font-family: ch-icon, sans-serif;
  color: #16192b;
  content: "\c1f1";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.125rem solid #f0f1f2;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #878c91;
}

code {
  font-size: 87.5%;
  color: #c70080;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #16192b;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #16192b;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 0.125rem solid #dde0e3;
}

.table th,
.table td {
  padding: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
  vertical-align: top;
  border: 0.125rem solid #dde0e3;
}

.table thead th {
  font-weight: 500;
  vertical-align: bottom;
}

.table thead th,
.table thead td {
  border-bottom-color: #a7aaad;
  border-bottom-width: 0.25rem;
}

.table thead th.double-header {
  font-weight: 700;
  text-align: center;
  border-bottom: 0.125rem solid #dde0e3;
}

.table tbody th[scope="colgroup"] {
  font-weight: 500;
  text-align: center;
  background-color: #f9fafb;
}

.table tbody + tbody {
  border-top: 0.25rem solid #dde0e3;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #f0f1f2;
}

.table .table {
  background-color: #fff;
}

.table caption {
  font-size: 1.75rem;
  line-height: 3rem;
  color: #878c91;
}

.table-sm th,
.table-sm td {
  padding: 1rem;
}

.table-lg th,
.table-lg td {
  padding: 2rem;
}

.table-borderless.table,
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-non-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-hover tbody tr:hover {
  background-color: rgba(22, 25, 43, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8bfe2;
}

.table-hover .table-primary:hover {
  background-color: #a6afdb;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a6afdb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f6b8e2;
}

.table-hover .table-secondary:hover {
  background-color: #f3a1d9;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f3a1d9;
}

.table-ink,
.table-ink > th,
.table-ink > td {
  background-color: #b8c4f7;
}

.table-hover .table-ink:hover {
  background-color: #a1b1f4;
}

.table-hover .table-ink:hover > td,
.table-hover .table-ink:hover > th {
  background-color: #a1b1f4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c5e4cd;
}

.table-hover .table-success:hover {
  background-color: #b4dcbe;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4dcbe;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeec6;
}

.table-hover .table-warning:hover {
  background-color: #ffe6ad;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe6ad;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f0c3bd;
}

.table-hover .table-danger:hover {
  background-color: #ebb0a8;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebb0a8;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bebfc4;
}

.table-hover .table-dark:hover {
  background-color: #b1b2b8;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b1b2b8;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(22, 25, 43, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(13, 15, 26, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(13, 15, 26, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 959.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(5.5rem + 0.25rem);
  padding: 1.5rem 2rem;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #16192b;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid #dde0e3;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #16192b;
  background-color: #fff;
  border-color: #001a96;
  outline: 0;
  box-shadow: 0 3px 6px 0 rgba(22, 25, 43, 0.1);
}

.form-control::placeholder {
  color: #a7aaad;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  color: #878c91;
  background-color: #f0f1f2;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #16192b;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(1.5rem + 0.125rem);
  padding-bottom: calc(1.5rem + 0.125rem);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 2.5rem;
}

.col-form-label-lg {
  padding-top: calc(1.875rem + 0.125rem);
  padding-bottom: calc(1.875rem + 0.125rem);
  font-size: 2.34375rem;
  line-height: 3.125rem;
}

.col-form-label-sm {
  padding-top: calc(1.125rem + 0.125rem);
  padding-bottom: calc(1.125rem + 0.125rem);
  font-size: 1.640625rem;
  line-height: 1.875rem;
}

.required {
  color: #c82914;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 0;
  line-height: 2.5rem;
  color: #16192b;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.125rem 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(4.125rem + 0.25rem);
  padding: 1.125rem 1.5rem;
  font-size: 1.640625rem;
  line-height: 1.875rem;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(6.875rem + 0.25rem);
  padding: 1.875rem 2.5rem;
  font-size: 2.34375rem;
  line-height: 3.125rem;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
  min-height: 10.75rem;
}

.form-group {
  margin-bottom: 2rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
}

.form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

[type="checkbox"] ~ .form-check-label::before, [type="radio"] ~ .form-check-label::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  content: "";
  background: #fff;
  border: 1px solid #dde0e3;
  border-radius: 0.375rem;
  transition: all .2s linear;
}

:disabled ~ [type="checkbox"] ~ .form-check-label::before, [type="checkbox"] ~ :disabled ~ .form-check-label::before, [type="checkbox"]:disabled ~ .form-check-label::before, :disabled ~ [type="radio"] ~ .form-check-label::before, [type="radio"] ~ :disabled ~ .form-check-label::before, [type="radio"]:disabled ~ .form-check-label::before {
  background-color: #f0f1f2;
}

.form-check:hover :enabled:not(:checked) ~ [type="checkbox"] ~ .form-check-label::before, .form-check:hover [type="checkbox"] ~ :enabled:not(:checked) ~ .form-check-label::before, .form-check:hover [type="checkbox"]:enabled:not(:checked) ~ .form-check-label::before, .form-check:hover :enabled:not(:checked) ~ [type="radio"] ~ .form-check-label::before, .form-check:hover [type="radio"] ~ :enabled:not(:checked) ~ .form-check-label::before, .form-check:hover [type="radio"]:enabled:not(:checked) ~ .form-check-label::before {
  border: 1px solid #001a96;
}

.form-check-label {
  padding-left: 4rem;
  margin-bottom: 0;
}

:disabled ~ .form-check-label {
  color: #878c91;
}

[type="checkbox"]:checked:not(:disabled) ~ .form-check-label::before {
  background-color: #001a96;
}

[type="checkbox"]:focus ~ .form-check-label::before {
  box-shadow: 0 0 5px #001a96;
}

[type="checkbox"] ~ .form-check-label::after {
  position: absolute;
  top: .125rem;
  left: .5rem;
  display: block;
  font-family: ch-icon, sans-serif;
  font-size: 1.5rem;
  line-height: 19px;
  color: #fff;
  content: "\c237";
}

:disabled ~ .form-check-label::after {
  color: #878c91;
}

:disabled:not(:checked) ~ .form-check-label::after {
  display: none;
}

[type="radio"] ~ .form-check-label::before {
  border-radius: 2.5rem;
}

[type="radio"]:checked:not(:disabled) ~ .form-check-label::before {
  border: 6px solid #001a96;
}

[type="radio"]:focus ~ .form-check-label::before {
  box-shadow: 0 0 5px #001a96;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 3.5rem;
}

.form-toggle-text-label {
  display: block;
  font-weight: 500;
}

/* The switch - the box around the slider */
.form-toggle-wrapper {
  position: relative;
  display: inline-block;
  width: 7.5rem;
  height: 4rem;
}

/* Hide default HTML checkbox */
.form-toggle-wrapper input {
  width: 0;
  height: 0;
  opacity: 0;
}

/* The slider */
.form-toggle-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 2rem;
  transition: 0.4s;
}

input:checked + .form-toggle-slider {
  background-color: #001a96;
}

input:disabled + .form-toggle-slider {
  opacity: 0.5;
}

input:focus + .form-toggle-slider {
  box-shadow: 0 0 5px #001a96;
}

.form-toggle-slider::before {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  width: 3rem;
  height: 3rem;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .form-toggle-slider::before {
  transform: translateX(3.5rem);
}

.form-select {
  display: inline-block;
  width: 100%;
  height: calc(5.5rem + 0.25rem);
  padding: 0.375rem 3rem 0.375rem 2rem;
  line-height: 1.6;
  color: #16192b;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 5 5,50 55,95 5,5 5 Z'/%3E%3C/svg%3E") no-repeat right 2rem center;
  background-size: 1rem 0.75rem;
  border: 0.125rem solid #dde0e3;
  border-radius: 0.375rem;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* stylelint-enable */
}

.form-select:focus {
  border-color: #001a96;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 26, 150, 0.5);
}

.form-select:focus::-ms-value {
  color: #16192b;
  background-color: #fff;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 2rem;
  background-image: none;
}

.form-select:disabled {
  color: #878c91;
  background-color: #f9fafb;
}

.form-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(4.125rem + 0.25rem);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(6.875rem + 0.25rem);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.625rem;
  color: #30a04d;
}

.was-validated :valid ~ .valid-feedback,
.is-valid ~ .valid-feedback {
  display: block;
}

.valid-tooltip {
  border-radius: 0.375rem;
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1rem 2rem;
  margin-top: .1rem;
  font-size: 1.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: #30a04d;
}

.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #30a04d;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #30a04d;
  box-shadow: 0 0 0 0.2rem rgba(48, 160, 77, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #30a04d;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #30a04d;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #79d791;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #3ec561;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(48, 160, 77, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #30a04d;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(48, 160, 77, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.625rem;
  color: #c82914;
}

.was-validated :invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-feedback {
  display: block;
}

.invalid-tooltip {
  border-radius: 0.375rem;
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1rem 2rem;
  margin-top: .1rem;
  font-size: 1.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: #c82914;
}

.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #c82914;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #c82914;
  box-shadow: 0 0 0 0.2rem rgba(200, 41, 20, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c82914;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #c82914;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #f07b6b;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e93d26;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(200, 41, 20, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #c82914;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(200, 41, 20, 0.25);
}

.was-validated :invalid,
.is-invalid {
  padding-right: 6rem;
}

.feedback-icon {
  right: 2rem;
  bottom: 4rem;
  font-size: 2rem;
  line-height: 2.5rem;
  position: relative;
  float: right;
  width: auto;
}

.form-control-sm ~ .feedback-icon {
  right: 1.5rem;
  bottom: 3rem;
  font-size: 1.640625rem;
  line-height: 1.875rem;
}

.form-control-lg ~ .feedback-icon {
  right: 2.5rem;
  bottom: 5rem;
  font-size: 2.34375rem;
  line-height: 3.125rem;
}

.textarea-with-icon .feedback-icon {
  position: absolute;
  top: 1.5rem;
  bottom: auto;
}

.textarea-with-icon {
  position: relative;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-right: 3.5rem;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 3rem;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-radius: 0.375rem;
  transition: all 0.1s ease-in;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn i {
  margin-left: 2rem;
}

.btn.disabled, .btn:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.btn.btn-primary {
  color: #fff;
  background: #e00099;
  border: #e00099 1px solid;
}

.btn.btn-primary:hover, .btn.btn-primary:focus {
  background: #e633ad;
}

.btn.btn-primary:active, .btn.btn-primary.highlighted {
  background: #c70080;
}

.btn.btn-primary.disabled, .btn.btn-primary:disabled {
  color: #a7aaad;
  background: #f0f1f2;
  border: #f0f1f2 1px solid;
}

.btn.btn-secondary {
  color: #001a96;
  background: #fff;
  border: #001a96 1px solid;
}

.btn.btn-secondary:hover, .btn.btn-secondary:focus {
  color: #fff;
  background: #002de4;
  border: #002de4 1px solid;
}

.btn.btn-secondary:active, .btn.btn-secondary.highlighted {
  color: #fff;
  background: #001a96;
  border: #001a96 1px solid;
}

.btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
  color: #878c91;
  background: #fff;
  border: #f0f1f2 1px solid;
}

.btn.btn-tertiary {
  color: #001a96;
  background: transparent;
  border: none;
}

.btn.btn-tertiary:hover, .btn.btn-tertiary:focus, .btn.btn-tertiary:active, .btn.btn-tertiary.highlighted {
  background: transparent;
}

.btn.btn-tertiary:hover, .btn.btn-tertiary:focus {
  color: #002de4;
}

.btn.btn-tertiary:active, .btn.btn-tertiary.highlighted {
  color: #001a96;
}

.btn.btn-tertiary.disabled, .btn.btn-tertiary:disabled {
  color: #a7aaad;
}

.btn.btn-icon {
  padding: 1.5rem;
}

.btn.btn-icon i {
  margin-left: 0;
}

.btn.btn-link {
  padding: 0;
  font-size: inherit;
  font-weight: 400;
  color: #002de4;
  background-color: transparent;
  border: none;
}

.btn.btn-link:hover {
  color: #e00099;
  text-decoration: none;
}

.btn.btn-sm {
  padding: 1rem 2rem;
  font-size: 1.75rem;
}

.btn.btn-block {
  display: block;
  width: 100%;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown.autocomplete .dropdown-menu {
  top: 100% !important;
}

.dropdown.autocomplete .ddi-input {
  background: #fff;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  max-height: 40rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  overflow-y: auto;
  font-size: 1.875rem;
  color: #16192b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid rgba(22, 25, 43, 0.15);
  border-radius: 0.375rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid #dde0e3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #16192b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
}

.dropdown-item.active {
  color: #fff;
  text-decoration: none;
  background-color: #001a96 !important;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #878c91;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 1.640625rem;
  color: #878c91;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #16192b;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -0.125rem;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -0.125rem;
}

.input-group-prepend {
  margin-right: -0.125rem;
}

.input-group-append {
  margin-left: -0.125rem;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: #16192b;
  text-align: center;
  white-space: nowrap;
  background-color: #f9fafb;
  border: 0.125rem solid #dde0e3;
  border-radius: 0.375rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(6.875rem + 0.25rem);
  padding: 1.875rem 2.5rem;
  font-size: 2.34375rem;
  line-height: 3.125rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(4.125rem + 0.25rem);
  padding: 1.125rem 1.5rem;
  font-size: 1.640625rem;
  line-height: 1.875rem;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #878c91;
}

.nav-tabs {
  border-bottom: 0.125rem solid #f0f1f2;
}

.nav-tabs .nav-item {
  margin-bottom: -0.125rem;
}

.nav-tabs .nav-link {
  border: 0.125rem solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f9fafb #f9fafb #f0f1f2;
}

.nav-tabs .nav-link.disabled {
  color: #878c91;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #717379;
  background-color: #fff;
  border-color: #f0f1f2 #f0f1f2 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.125rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #001a96;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-right: 1rem;
  font-size: 2.34375rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2.34375rem;
  line-height: 1;
  background-color: transparent;
  border: 0.125rem solid transparent;
  border-radius: 0.375rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 959.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 960px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(22, 25, 43, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(22, 25, 43, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(22, 25, 43, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(22, 25, 43, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(22, 25, 43, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(22, 25, 43, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(22, 25, 43, 0.5);
  border-color: rgba(22, 25, 43, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(22, 25, 43, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(22, 25, 43, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(22, 25, 43, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(22, 25, 43, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.breadcrumb-item {
  line-height: 2;
}

.breadcrumb-item:not(:last-child) {
  padding-right: 2rem;
}

.breadcrumb-item:not(:last-child)::after {
  display: inline-block;
  padding-left: 2rem;
  color: #717379;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #717379;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination ul.pages {
  display: flex;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

@media (max-width: 767.98px) {
  .pagination ul.pages {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.pagination .page {
  margin: 0 1rem;
}

@media (max-width: 767.98px) {
  .pagination .page {
    margin: 0 0.5rem;
  }
  .pagination .page:first-of-type {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    margin-left: 0;
  }
  .pagination .page:first-of-type .page-link {
    padding: 0;
  }
  .pagination .page:last-of-type {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 0;
  }
  .pagination .page:last-of-type .page-link {
    padding: 0;
  }
}

.pagination .page .page-link.active {
  z-index: 1;
  font-weight: 700;
  color: #16192b;
  border-bottom: 3px solid #16192b;
}

.pagination .page .page-link.disabled {
  color: #dde0e3;
  pointer-events: none;
  cursor: not-allowed;
}

.pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: #002de4;
  border-bottom: 3px solid #fff;
}

@media (max-width: 767.98px) {
  .pagination .page-link {
    padding: 0.5rem;
  }
}

.pagination .page-link:visited {
  color: #002de4;
}

.pagination .page-link:hover {
  z-index: 2;
  color: #16192b;
  text-decoration: none;
}

.pagination .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 26, 150, 0.25);
}

.pagination .page-link i[class^="chi-pointer-"] {
  font-size: 1.7rem;
  font-weight: 800;
}

.pagination .page-prev {
  margin-left: 1rem;
}

@media (max-width: 767.98px) {
  .pagination .page-prev {
    display: none;
  }
}

.pagination .page-next {
  margin-right: 1rem;
}

@media (max-width: 767.98px) {
  .pagination .page-next {
    display: none;
  }
}

.pagination .page-more {
  position: relative;
  display: block;
  padding: 0.5rem 0;
  font-weight: 500;
  color: #16192b;
  border-bottom: 3px solid #fff;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.list-group-item-action {
  width: 100%;
  color: #717379;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #717379;
  text-decoration: none;
  background-color: #fff;
}

.list-group-item-action:active {
  color: #16192b;
  background-color: #f9fafb;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 2rem 3rem;
  margin-bottom: -0.125rem;
  line-height: 1.5;
  background-color: #fff;
  border: 0.125rem solid #dde0e3;
}

@media (max-width: 767.98px) {
  .list-group-item {
    padding: 2rem 2rem;
  }
}

.list-group-item:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #878c91;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #001a96;
  border-color: #001a96;
}

.list-group-item .item-subtitle {
  font-size: 1.625rem;
  color: #717379;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-no-divider .list-group-item {
  border-top: 0;
  border-bottom: 0;
}

.list-group-no-divider:first-child .list-group-item:first-child {
  border-top: 0.125rem solid #dde0e3;
}

.list-group-no-divider:last-child .list-group-item:last-child {
  border-bottom: 0.125rem solid #dde0e3;
}

.list-group-item-primary {
  color: #0b1a63;
  background-color: #b8bfe2;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0b1a63;
  background-color: #a6afdb;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0b1a63;
  border-color: #0b1a63;
}

.list-group-item-secondary {
  color: #7f0c64;
  background-color: #f6b8e2;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7f0c64;
  background-color: #f3a1d9;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7f0c64;
  border-color: #7f0c64;
}

.list-group-item-ink {
  color: #0b238b;
  background-color: #b8c4f7;
}

.list-group-item-ink.list-group-item-action:hover, .list-group-item-ink.list-group-item-action:focus {
  color: #0b238b;
  background-color: #a1b1f4;
}

.list-group-item-ink.list-group-item-action.active {
  color: #fff;
  background-color: #0b238b;
  border-color: #0b238b;
}

.list-group-item-success {
  color: #245f3d;
  background-color: #c5e4cd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #245f3d;
  background-color: #b4dcbe;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #245f3d;
  border-color: #245f3d;
}

.list-group-item-warning {
  color: #8f7130;
  background-color: #ffeec6;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #8f7130;
  background-color: #ffe6ad;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #8f7130;
  border-color: #8f7130;
}

.list-group-item-danger {
  color: #73211f;
  background-color: #f0c3bd;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #73211f;
  background-color: #ebb0a8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #73211f;
  border-color: #73211f;
}

.list-group-item-light {
  color: #8f9199;
  background-color: white;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #8f9199;
  background-color: #f2f2f2;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #8f9199;
  border-color: #8f9199;
}

.list-group-item-dark {
  color: #16192b;
  background-color: #bebfc4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #16192b;
  background-color: #b1b2b8;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #16192b;
  border-color: #16192b;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (5rem * 2));
  content: "";
}

.modal-close {
  position: absolute;
  top: -4rem;
  right: 0;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  border-radius: 0.375rem;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #16192b;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 6rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (6rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (6rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 960px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-size: 1.875rem;
  word-wrap: break-word;
  visibility: hidden;
  font-family: "Haas Grot Text Web", "Helvetica Neue", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}

.tooltip.show {
  visibility: visible;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 2.5rem;
  height: 2rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.tooltip-container {
  position: relative;
}

.tooltip-top {
  padding: 2rem 0;
  transform: translate(-50%, -103%);
}

.tooltip-top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -5%);
}

.tooltip-top .tooltip-arrow::before {
  top: 0;
  border-width: 2rem 1.25rem 0;
  border-top-color: #16192b;
}

.tooltip-right {
  padding: 0 2rem;
  transform: translate(3%, -50%);
}

.tooltip-right .tooltip-arrow {
  top: 50%;
  left: 0;
  width: 2rem;
  height: 2.5rem;
  transform: translate(5%, -50%);
}

.tooltip-right .tooltip-arrow::before {
  right: 0;
  border-width: 1.25rem 2rem 1.25rem 0;
  border-right-color: #16192b;
}

.tooltip-bottom {
  padding: 2rem 0;
  transform: translate(-50%, 3%);
}

.tooltip-bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  transform: translate(-50%, 5%);
}

.tooltip-bottom .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 1.25rem 2rem;
  border-bottom-color: #16192b;
}

.tooltip-left {
  padding: 0 2rem;
  transform: translate(-103%, -50%);
}

.tooltip-left .tooltip-arrow {
  top: 50%;
  right: 0;
  width: 2rem;
  height: 2.5rem;
  transform: translate(-5%, -50%);
}

.tooltip-left .tooltip-arrow::before {
  left: 0;
  border-width: 1.25rem 0 1.25rem 2rem;
  border-left-color: #16192b;
}

.tooltip-inner {
  max-width: 20vw;
  padding: 1rem 2rem;
  line-height: 1.6;
  color: #fff;
  text-align: center;
  background-color: #16192b;
  border-radius: 0.375rem;
}

.tooltip.tooltip-compound .tooltip-arrow {
  display: none;
}

.tooltip.tooltip-compound-light .tooltip-inner {
  color: #16192b;
  background-color: #fff;
  box-shadow: 0 0 10px #dde0e3;
}

.tooltip.tooltip-compound-light .tooltip-arrow {
  display: none;
}

.tooltip.tooltip-error.tooltip-top .tooltip-arrow::before {
  border-top-color: #c82914;
}

.tooltip.tooltip-error.tooltip-right .tooltip-arrow::before {
  border-right-color: #c82914;
}

.tooltip.tooltip-error.tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #c82914;
}

.tooltip.tooltip-error.tooltip-left .tooltip-arrow::before {
  border-left-color: #c82914;
}

.tooltip.tooltip-error .tooltip-inner {
  background-color: #c82914;
}

.tooltip.tooltip-success.tooltip-top .tooltip-arrow::before {
  border-top-color: #30a04d;
}

.tooltip.tooltip-success.tooltip-right .tooltip-arrow::before {
  border-right-color: #30a04d;
}

.tooltip.tooltip-success.tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #30a04d;
}

.tooltip.tooltip-success.tooltip-left .tooltip-arrow::before {
  border-left-color: #30a04d;
}

.tooltip.tooltip-success .tooltip-inner {
  background-color: #30a04d;
}

.tooltip.tooltip-warning.tooltip-top .tooltip-arrow::before {
  border-top-color: #ffc334;
}

.tooltip.tooltip-warning.tooltip-right .tooltip-arrow::before {
  border-right-color: #ffc334;
}

.tooltip.tooltip-warning.tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #ffc334;
}

.tooltip.tooltip-warning.tooltip-left .tooltip-arrow::before {
  border-left-color: #ffc334;
}

.tooltip.tooltip-warning .tooltip-inner {
  color: #16192b;
  background-color: #ffc334;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Haas Grot Text Web", "Helvetica Neue", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.640625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid rgba(22, 25, 43, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 0.125rem) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(22, 25, 43, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0.125rem;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 0.125rem) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(22, 25, 43, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0.125rem;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 0.125rem) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(22, 25, 43, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0.125rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0.125rem solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 0.125rem) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(22, 25, 43, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0.125rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 0.125rem solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 0.125rem);
  border-top-right-radius: calc(0.3rem - 0.125rem);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #16192b;
}

.tabs {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: inset 0 -2px 0 #dde0e3;
  -webkit-overflow-scrolling: touch;
  padding-left: 0;
  list-style: none;
}

.tabs .tab {
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-width: 200px;
  padding: 0 4rem;
  font-weight: 400;
  line-height: 3;
  color: #404245;
  cursor: pointer;
  border-bottom: .25rem solid transparent;
  outline-offset: -4px;
}

.tabs .tab:hover {
  font-weight: 400;
  color: #e00099;
}

.tabs .tab.active {
  font-weight: 500;
  color: #001a96;
  border-bottom-color: #001a96;
}

.tabs .tab.disabled, .tabs .tab.disabled:hover {
  font-weight: 400;
  color: #a7aaad;
  cursor: not-allowed;
  border-bottom-color: transparent;
}

.tabs.tabs-fitted .tab {
  flex: 1;
  flex-basis: 0;
  max-width: none;
}

.tabs.tabs-scrollable {
  overflow-x: auto;
}

.tabs.tabs-scrollable .tab {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
}

.tabs.tabs-extended .tab {
  flex-direction: column;
  max-width: none;
  padding: 1.6rem 4rem;
  line-height: initial;
  color: #16192b;
  text-align: left;
}

.tabs.tabs-extended .tab .tab-title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #404245;
}

.tabs.tabs-extended .tab .tab-subtitle {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2;
  color: #717379;
}

.tabs.tabs-extended .tab:hover .tab-title {
  font-weight: 400;
  color: #e00099;
}

.tabs.tabs-extended .tab:hover .tab-subtitle {
  font-weight: 400;
  color: #404245;
}

.tabs.tabs-extended .active .tab-title,
.tabs.tabs-extended .active:hover .tab-title {
  font-weight: 500;
  color: #001a96;
}

.tabs.tabs-extended .active .tab-subtitle,
.tabs.tabs-extended .active:hover .tab-subtitle {
  font-weight: 400;
  color: #404245;
}

.tabs.tabs-extended .disabled .tab-title,
.tabs.tabs-extended .disabled .tab-subtitle,
.tabs.tabs-extended .disabled:hover .tab-title,
.tabs.tabs-extended .disabled:hover .tab-subtitle {
  font-weight: 400;
  color: #a7aaad;
}

.tabs.tabs-centered {
  justify-content: center;
}

.tabs.tabs-split-equally .tab {
  flex-basis: 100%;
  max-width: 100%;
}

.tabs.tabs-right {
  justify-content: flex-end;
}

.tags {
  display: inline-flex;
  align-items: center;
  padding: .5rem 1rem;
  color: #16192b;
  background: #dde0e3;
  border-radius: 0.375rem;
}

.tags i {
  margin-left: 1rem;
  font-size: 2rem;
  vertical-align: middle;
}

.tags a {
  color: #16192b;
}

.tags a:visited {
  color: #16192b;
}

.tags a:hover, .tags a:active, .tags a:focus {
  color: #001a96;
}

.tags.tags-white {
  background: #fff;
  border: #16192b 1px solid;
}

.tags.tags-plain {
  background: transparent;
}

a.tags {
  color: #16192b;
}

a.tags:visited {
  color: #16192b;
}

a.tags:hover {
  color: #fff;
  background: #16192b;
}

a.tags:active {
  color: #fff;
}

a.tags:focus {
  color: #fff;
  background: #001a96;
}

.card {
  position: relative;
  display: flex;
  border-radius: 0.375rem;
  box-shadow: 0 0 10px #dde0e3;
}

@media (min-width: 768px) {
  .card {
    display: block;
  }
}

.card .card-body {
  flex: 1;
  flex-basis: 25%;
  padding: 3rem;
}

.card .card-title {
  font-size: 2.34375rem;
  font-weight: 700;
}

.card .card-subtitle {
  margin-top: .4rem;
  color: #404245;
}

.card .card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 0.375rem 0.375rem;
}

.card .card-image {
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 768px) {
  .card .card-image {
    flex: none;
    height: 168px;
  }
}

.card .card-image.card-image-top {
  border-radius: 0.375rem 0.375rem 0 0;
}

.card .card-image.card-image-bottom {
  border-radius: 0 0 0.375rem 0.375rem;
}

.card .card-image.card-image-full {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
}

.prompt {
  position: relative;
  padding: 2.5rem 3.75rem 2.5rem 10rem;
  background: #fff;
  box-shadow: 0 0 10px #dde0e3;
  border-radius: 0.375rem;
}

.prompt .prompt-heading {
  margin-left: 1rem;
  font-size: 2.25rem;
  font-weight: 700;
}

.prompt .prompt-close {
  position: absolute;
  top: 50%;
  right: 3rem;
  font-size: 2.25rem;
  color: #16192b;
  cursor: pointer;
  transform: translateY(-50%);
}

.prompt .prompt-cta {
  position: absolute;
  top: 50%;
  right: 8.25rem;
  transform: translateY(-50%);
}

.prompt p {
  margin: 1rem 0 0 1rem;
}

.prompt .prompt-icon {
  position: absolute;
  top: 50%;
  left: 3rem;
  font-family: "ch-icon";
  font-size: 3.375rem;
  transform: translateY(-50%);
}

.prompt.prompt-dark {
  color: #fff;
  background: #16192b;
}

.prompt.prompt-dark .prompt-close {
  color: #fff;
}

.prompt.prompt-floater {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  color: #fff;
  background: #001a96;
  border-radius: 0;
  box-shadow: none;
}

.prompt.prompt-floater::before {
  display: none;
}

.prompt.prompt-floater.prompt-floater-top {
  top: 0;
  bottom: auto;
}

.prompt.prompt-floater .prompt-icon {
  color: #fff;
}

.prompt.prompt-floater .prompt-close {
  color: #fff;
}

.prompt.prompt-floater.prompt-success {
  background: #30a04d;
}

.prompt.prompt-floater.prompt-warning {
  color: #16192b;
  background: #ffc334;
}

.prompt.prompt-floater.prompt-warning .prompt-icon,
.prompt.prompt-floater.prompt-warning .prompt-close {
  color: #16192b;
}

.prompt.prompt-floater.prompt-danger {
  background: #c82914;
}

.prompt.prompt-floater.prompt-snackbar {
  bottom: 3rem;
  left: 3rem;
  width: auto;
  border-radius: 0.375rem;
}

@media (max-width: 767.98px) {
  .prompt.prompt-floater.prompt-snackbar {
    left: 50%;
    width: 95%;
    transform: translate(-50%, 0);
  }
}

.prompt.prompt-banner {
  padding-left: 3rem;
  box-shadow: none;
  border-radius: 0;
}

.prompt.prompt-banner::before {
  display: none;
}

.prompt.prompt-banner .prompt-banner-text {
  max-width: 50%;
  font-weight: 700;
}

.prompt.prompt-banner.prompt-success {
  background: #30a04d;
}

.prompt.prompt-banner.prompt-warning {
  color: #16192b;
  background: #ffc334;
}

.prompt.prompt-banner.prompt-warning .prompt-close {
  color: #16192b;
}

.prompt.prompt-banner.prompt-danger {
  color: #fff;
  background: #c82914;
}

.prompt.prompt-banner.prompt-danger .prompt-close,
.prompt.prompt-banner.prompt-danger .prompt-cta {
  color: #fff;
}

.label {
  display: inline-block;
  padding: .5rem 1.25rem;
  font-size: 1.625rem;
  font-weight: 500;
  text-align: center;
  background: #a7aaad;
  border-radius: 0.375rem;
}

.label.label-primary, .label.label-success, .label.label-danger {
  color: #fff;
}

.label.label-archived {
  color: #a7aaad;
  background: #dde0e3;
}

.label.label-primary {
  background: #001a96;
}

.label.label-success {
  background: #30a04d;
}

.label.label-warning {
  background: #ffc334;
}

.label.label-danger {
  background: #c82914;
}

.badge-wrapper {
  display: flex;
  align-items: center;
}

.badge-wrapper .badge {
  margin-left: 8px;
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  padding: 4px 6px;
  font-size: 1.625rem;
  line-height: 90%;
  color: #fff;
  background: #001a96;
  border-radius: 20px;
}

.badge.badge-disabled {
  color: #a7aaad;
  background: #dde0e3;
}

.badge.badge-success {
  background: #30a04d;
}

.badge.badge-danger {
  background: #c82914;
}

.panel {
  background: #fff;
  border-radius: 0.375rem;
}

.panel.panel-bordered {
  border: #dde0e3 1px solid;
}

.panel.panel-elevated {
  box-shadow: 0 0 1.25rem 0.25rem rgba(22, 25, 43, 0.1);
}

.panel div.panel-divider {
  border-top: #dde0e3 1px solid;
}

.panel div.panel-divider.panel-divider-light {
  border-top-color: #f0f1f2;
}

.spinner {
  display: inline-block;
  animation: rotate 1s linear infinite;
}

.spinner.spinner-lg {
  font-size: 8rem;
}

.spinner.spinner-md {
  font-size: 4rem;
}

.spinner.spinner-sm {
  font-size: 2rem;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.ddi-wrapper .dropdown-item, .ddi-category-option {
  width: 100%;
  padding: 1.5rem 2rem;
  line-height: 2.5rem;
  text-align: left;
  border: none;
}

.ddi-wrapper .dropdown-item:first-child, .ddi-category-option:first-child {
  padding-top: 1.5625rem;
}

.ddi-wrapper {
  position: relative;
}

.ddi-inline {
  display: inline-block;
}

.ddi-input, .ddi-input-btn, .ddi-multi-selected-list {
  padding: 1.5rem 2rem;
  line-height: 2.5rem;
  border: 0.125rem solid #dde0e3;
  border-radius: 0.375rem;
}

.ddi-input {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 5 5,50 55,95 5,5 5 Z'/%3E%3C/svg%3E") no-repeat right 2rem top 2.25rem;
  background-size: 1rem 0.75rem;
  width: 100%;
  cursor: default;
}

.was-validated .ddi-input:valid,
.is-valid .ddi-input {
  border-color: #30a04d;
}

.was-validated .ddi-input:valid:focus,
.is-valid .ddi-input:focus {
  border-color: #30a04d;
  box-shadow: 0 0 0 0.2rem rgba(48, 160, 77, 0.25);
}

.was-validated .ddi-input:invalid,
.is-invalid .ddi-input {
  border-color: #c82914;
}

.was-validated .ddi-input:invalid:focus,
.is-invalid .ddi-input:focus {
  border-color: #c82914;
  box-shadow: 0 0 0 0.2rem rgba(200, 41, 20, 0.25);
}

.ddi-input:disabled {
  background: #f0f1f2 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 5 5,50 55,95 5,5 5 Z'/%3E%3C/svg%3E") no-repeat right 2rem top 2.25rem;
  background-size: 1rem 0.75rem;
}

.ddi-input::placeholder {
  color: #a7aaad;
  opacity: 1;
}

.ddi-input-btn {
  background: #fff;
}

.ddi-input-btn[aria-expanded="true"] {
  background: #dde0e3;
}

.ddi-input-btn.btn-link {
  display: inline-flex;
  padding: 0;
  font-size: inherit;
  font-weight: 400;
  background: transparent;
  border: none;
}

.ddi-input-btn.btn-link::after {
  display: inline-block;
  width: 1rem;
  height: .75rem;
  margin: .625rem 0 0 1rem;
  content: "";
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 5 5,50 55,95 5,5 5 Z'/%3E%3C/svg%3E") no-repeat right 0 top 0;
}

.ddi-input-btn i {
  line-height: 2.5rem;
}

.ddi-wrapper .dropdown-menu {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 50 5,5 55,95 55,50 5 Z'/%3E%3C/svg%3E") no-repeat right 2rem top 2.25rem;
  background-size: 1rem 0.75rem;
  top: 0;
  width: 100%;
  min-width: 25rem;
  margin: -.0625rem 0 0;
  border: 0.125rem solid #001a96;
  border-radius: 0.375rem;
}

.ddi-input-btn ~ .dropdown-menu {
  margin: 6.75rem 0 0;
  background: #fff;
}

.ddi-multi .dropdown-menu {
  top: auto;
  margin: -.125rem 0 0;
  border-top: 0.125rem solid #dde0e3;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-link ~ .dropdown-menu {
  margin: 3.5rem 0 0;
}

.ddi-wrapper .dropdown-item {
  background: #fff;
}

.ddi-wrapper .dropdown-item:hover {
  color: #fff;
  background: #001a96;
}

.dropdown-item.dropdown-item-placeholder {
  color: #a7aaad;
}

.dropdown-item.dropdown-item-placeholder:hover {
  color: #a7aaad;
  background: transparent;
}

.ddi-category {
  position: relative;
  border-bottom: 1px solid #dde0e3;
}

.dropdown-item-placeholder + .ddi-category {
  border-top: 1px solid #dde0e3;
}

.ddi-category:last-child {
  border-bottom: none;
}

.ddi-category-search-icon {
  position: absolute;
  top: 1.5rem;
  left: 1.75rem;
  display: block;
  font-size: 2.5rem;
  color: #e00099;
}

.ddi-category-search {
  padding-left: 5rem;
  border: none;
}

.ddi-category-option {
  background: #fff;
}

.ddi-category-option:hover {
  color: #fff;
  background: #001a96;
}

.ddi-item-icon {
  padding: 0 2rem 0 0;
  font-size: 2rem;
}

.ddi-item-icon-right {
  position: absolute;
  right: 0;
}

.ddi-multi-selected-list {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 5 5,50 55,95 5,5 5 Z'/%3E%3C/svg%3E") no-repeat right 2rem top 2.25rem;
  background-size: 1rem 0.75rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 1rem 0;
  margin: 0;
  list-style-type: none;
  background-position-y: 2.625rem;
}

.was-validated .ddi-multi-selected-list:valid,
.is-valid .ddi-multi-selected-list {
  border-color: #30a04d;
}

.was-validated .ddi-multi-selected-list:valid:focus,
.is-valid .ddi-multi-selected-list:focus {
  border-color: #30a04d;
  box-shadow: 0 0 0 0.2rem rgba(48, 160, 77, 0.25);
}

.was-validated .ddi-multi-selected-list:invalid,
.is-invalid .ddi-multi-selected-list {
  border-color: #c82914;
}

.was-validated .ddi-multi-selected-list:invalid:focus,
.is-invalid .ddi-multi-selected-list:focus {
  border-color: #c82914;
  box-shadow: 0 0 0 0.2rem rgba(200, 41, 20, 0.25);
}

.ddi-multi-selected-list[aria-expanded="true"] {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 60' %3E%3Cpath fill='%23a7aaad' d='M 50 5,5 55,95 55,50 5 Z'/%3E%3C/svg%3E") no-repeat right 2rem top 2.25rem;
  background-size: 1rem 0.75rem;
  background-position-y: 2.625rem;
  border: 0.125rem solid #001a96;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ddi-multi-menu-selected-list {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
}

.ddi-multi-selected-list-item {
  display: inline-flex;
  align-items: center;
  padding: 0.875rem 1rem;
  margin: 0 1rem 1rem 0;
  font-size: 2rem;
  line-height: 2rem;
  color: #878c91;
  background: #f0f1f2;
  border-radius: 0.375rem;
}

.ddi-multi-selected-remove {
  padding: 0;
  margin: 0 0 0 1rem;
  color: #a7aaad;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary-100 {
  background-color: #9ea8d7 !important;
}

a.bg-primary-100:hover, a.bg-primary-100:focus,
button.bg-primary-100:hover,
button.bg-primary-100:focus {
  background-color: #7a88c8 !important;
}

.bg-primary-500 {
  background-color: #001a96 !important;
}

a.bg-primary-500:hover, a.bg-primary-500:focus,
button.bg-primary-500:hover,
button.bg-primary-500:focus {
  background-color: #001163 !important;
}

.bg-primary-700 {
  background-color: #283593 !important;
}

a.bg-primary-700:hover, a.bg-primary-700:focus,
button.bg-primary-700:hover,
button.bg-primary-700:focus {
  background-color: #1d276b !important;
}

.bg-primary-a700 {
  background-color: #002de4 !important;
}

a.bg-primary-a700:hover, a.bg-primary-a700:focus,
button.bg-primary-a700:hover,
button.bg-primary-a700:focus {
  background-color: #0023b1 !important;
}

.bg-primary-900 {
  background-color: #001471 !important;
}

a.bg-primary-900:hover, a.bg-primary-900:focus,
button.bg-primary-900:hover,
button.bg-primary-900:focus {
  background-color: #000b3e !important;
}

.bg-secondary-100 {
  background-color: #f39ed8 !important;
}

a.bg-secondary-100:hover, a.bg-secondary-100:focus,
button.bg-secondary-100:hover,
button.bg-secondary-100:focus {
  background-color: #ed71c6 !important;
}

.bg-secondary-500 {
  background-color: #e00099 !important;
}

a.bg-secondary-500:hover, a.bg-secondary-500:focus,
button.bg-secondary-500:hover,
button.bg-secondary-500:focus {
  background-color: #ad0076 !important;
}

.bg-secondary-700 {
  background-color: #e633ad !important;
}

a.bg-secondary-700:hover, a.bg-secondary-700:focus,
button.bg-secondary-700:hover,
button.bg-secondary-700:focus {
  background-color: #cd1994 !important;
}

.bg-secondary-900 {
  background-color: #c70080 !important;
}

a.bg-secondary-900:hover, a.bg-secondary-900:focus,
button.bg-secondary-900:hover,
button.bg-secondary-900:focus {
  background-color: #94005f !important;
}

.bg-alternative-danger-red {
  background-color: #c82914 !important;
}

a.bg-alternative-danger-red:hover, a.bg-alternative-danger-red:focus,
button.bg-alternative-danger-red:hover,
button.bg-alternative-danger-red:focus {
  background-color: #9a1f0f !important;
}

.bg-alternative-success-green {
  background-color: #30a04d !important;
}

a.bg-alternative-success-green:hover, a.bg-alternative-success-green:focus,
button.bg-alternative-success-green:hover,
button.bg-alternative-success-green:focus {
  background-color: #24793a !important;
}

.bg-alternative-warning-yellow {
  background-color: #ffc334 !important;
}

a.bg-alternative-warning-yellow:hover, a.bg-alternative-warning-yellow:focus,
button.bg-alternative-warning-yellow:hover,
button.bg-alternative-warning-yellow:focus {
  background-color: #ffb401 !important;
}

.bg-neutral-100 {
  background-color: #fff !important;
}

a.bg-neutral-100:hover, a.bg-neutral-100:focus,
button.bg-neutral-100:hover,
button.bg-neutral-100:focus {
  background-color: #e6e6e6 !important;
}

.bg-neutral-200 {
  background-color: #f9fafb !important;
}

a.bg-neutral-200:hover, a.bg-neutral-200:focus,
button.bg-neutral-200:hover,
button.bg-neutral-200:focus {
  background-color: #dae1e7 !important;
}

.bg-neutral-300 {
  background-color: #f0f1f2 !important;
}

a.bg-neutral-300:hover, a.bg-neutral-300:focus,
button.bg-neutral-300:hover,
button.bg-neutral-300:focus {
  background-color: #d5d8da !important;
}

.bg-neutral-400 {
  background-color: #dde0e3 !important;
}

a.bg-neutral-400:hover, a.bg-neutral-400:focus,
button.bg-neutral-400:hover,
button.bg-neutral-400:focus {
  background-color: #c1c7cc !important;
}

.bg-neutral-500 {
  background-color: #a7aaad !important;
}

a.bg-neutral-500:hover, a.bg-neutral-500:focus,
button.bg-neutral-500:hover,
button.bg-neutral-500:focus {
  background-color: #8d9194 !important;
}

.bg-neutral-600 {
  background-color: #878c91 !important;
}

a.bg-neutral-600:hover, a.bg-neutral-600:focus,
button.bg-neutral-600:hover,
button.bg-neutral-600:focus {
  background-color: #6e7377 !important;
}

.bg-neutral-700 {
  background-color: #717379 !important;
}

a.bg-neutral-700:hover, a.bg-neutral-700:focus,
button.bg-neutral-700:hover,
button.bg-neutral-700:focus {
  background-color: #585a5f !important;
}

.bg-neutral-800 {
  background-color: #404245 !important;
}

a.bg-neutral-800:hover, a.bg-neutral-800:focus,
button.bg-neutral-800:hover,
button.bg-neutral-800:focus {
  background-color: #27292b !important;
}

.bg-neutral-900 {
  background-color: #16192b !important;
}

a.bg-neutral-900:hover, a.bg-neutral-900:focus,
button.bg-neutral-900:hover,
button.bg-neutral-900:focus {
  background-color: #050509 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 0.125rem solid #f0f1f2 !important;
}

.border-top {
  border-top: 0.125rem solid #f0f1f2 !important;
}

.border-right {
  border-right: 0.125rem solid #f0f1f2 !important;
}

.border-bottom {
  border-bottom: 0.125rem solid #f0f1f2 !important;
}

.border-left {
  border-left: 0.125rem solid #f0f1f2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary-100 {
  border-color: #9ea8d7 !important;
}

.border-primary-500 {
  border-color: #001a96 !important;
}

.border-primary-700 {
  border-color: #283593 !important;
}

.border-primary-a700 {
  border-color: #002de4 !important;
}

.border-primary-900 {
  border-color: #001471 !important;
}

.border-secondary-100 {
  border-color: #f39ed8 !important;
}

.border-secondary-500 {
  border-color: #e00099 !important;
}

.border-secondary-700 {
  border-color: #e633ad !important;
}

.border-secondary-900 {
  border-color: #c70080 !important;
}

.border-alternative-danger-red {
  border-color: #c82914 !important;
}

.border-alternative-success-green {
  border-color: #30a04d !important;
}

.border-alternative-warning-yellow {
  border-color: #ffc334 !important;
}

.border-neutral-100 {
  border-color: #fff !important;
}

.border-neutral-200 {
  border-color: #f9fafb !important;
}

.border-neutral-300 {
  border-color: #f0f1f2 !important;
}

.border-neutral-400 {
  border-color: #dde0e3 !important;
}

.border-neutral-500 {
  border-color: #a7aaad !important;
}

.border-neutral-600 {
  border-color: #878c91 !important;
}

.border-neutral-700 {
  border-color: #717379 !important;
}

.border-neutral-800 {
  border-color: #404245 !important;
}

.border-neutral-900 {
  border-color: #16192b !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 960px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 960px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 960px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.list-unstyled {
  padding: 0;
  list-style: none;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(22, 25, 43, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(22, 25, 43, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(22, 25, 43, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1,
.my-1 {
  margin-top: 1rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 1rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 1rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.mt-2,
.my-2 {
  margin-top: 2rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 2rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 2rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 2rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mt-3,
.my-3 {
  margin-top: 3rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 3rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 3rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 3rem !important;
}

.m-4 {
  margin: 4rem !important;
}

.mt-4,
.my-4 {
  margin-top: 4rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 4rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 4rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 4rem !important;
}

.m-5 {
  margin: 5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 5rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.mt-6,
.my-6 {
  margin-top: 6rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 6rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 6rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 6rem !important;
}

.m-7 {
  margin: 7rem !important;
}

.mt-7,
.my-7 {
  margin-top: 7rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 7rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 7rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 7rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.mt-8,
.my-8 {
  margin-top: 8rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 8rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 8rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 8rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.mt-9,
.my-9 {
  margin-top: 9rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 9rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 9rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 9rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.mt-10,
.my-10 {
  margin-top: 10rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 10rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.pt-1,
.py-1 {
  padding-top: 1rem !important;
}

.pr-1,
.px-1 {
  padding-right: 1rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 1rem !important;
}

.pl-1,
.px-1 {
  padding-left: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.pt-2,
.py-2 {
  padding-top: 2rem !important;
}

.pr-2,
.px-2 {
  padding-right: 2rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 2rem !important;
}

.pl-2,
.px-2 {
  padding-left: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.pt-3,
.py-3 {
  padding-top: 3rem !important;
}

.pr-3,
.px-3 {
  padding-right: 3rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 3rem !important;
}

.pl-3,
.px-3 {
  padding-left: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.pt-4,
.py-4 {
  padding-top: 4rem !important;
}

.pr-4,
.px-4 {
  padding-right: 4rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 4rem !important;
}

.pl-4,
.px-4 {
  padding-left: 4rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 5rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.pt-6,
.py-6 {
  padding-top: 6rem !important;
}

.pr-6,
.px-6 {
  padding-right: 6rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.pl-6,
.px-6 {
  padding-left: 6rem !important;
}

.p-7 {
  padding: 7rem !important;
}

.pt-7,
.py-7 {
  padding-top: 7rem !important;
}

.pr-7,
.px-7 {
  padding-right: 7rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 7rem !important;
}

.pl-7,
.px-7 {
  padding-left: 7rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.pt-8,
.py-8 {
  padding-top: 8rem !important;
}

.pr-8,
.px-8 {
  padding-right: 8rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 8rem !important;
}

.pl-8,
.px-8 {
  padding-left: 8rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.pt-9,
.py-9 {
  padding-top: 9rem !important;
}

.pr-9,
.px-9 {
  padding-right: 9rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 9rem !important;
}

.pl-9,
.px-9 {
  padding-left: 9rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.pt-10,
.py-10 {
  padding-top: 10rem !important;
}

.pr-10,
.px-10 {
  padding-right: 10rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10rem !important;
}

.pl-10,
.px-10 {
  padding-left: 10rem !important;
}

.m-n1 {
  margin: -1rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -1rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -1rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -1rem !important;
}

.m-n2 {
  margin: -2rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -2rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -2rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -2rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -2rem !important;
}

.m-n3 {
  margin: -3rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -3rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -3rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -3rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -3rem !important;
}

.m-n4 {
  margin: -4rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -4rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -4rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -4rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -4rem !important;
}

.m-n5 {
  margin: -5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -5rem !important;
}

.m-n6 {
  margin: -6rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -6rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important;
}

.m-n7 {
  margin: -7rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -7rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important;
}

.m-n8 {
  margin: -8rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -8rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important;
}

.m-n9 {
  margin: -9rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -9rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important;
}

.m-n10 {
  margin: -10rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -10rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 1rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important;
  }
  .m-sm-2 {
    margin: 2rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2rem !important;
  }
  .m-sm-3 {
    margin: 3rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3rem !important;
  }
  .m-sm-4 {
    margin: 4rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4rem !important;
  }
  .m-sm-5 {
    margin: 5rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem !important;
  }
  .m-sm-6 {
    margin: 6rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important;
  }
  .m-sm-7 {
    margin: 7rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important;
  }
  .m-sm-10 {
    margin: 10rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 1rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important;
  }
  .p-sm-2 {
    padding: 2rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2rem !important;
  }
  .p-sm-3 {
    padding: 3rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3rem !important;
  }
  .p-sm-4 {
    padding: 4rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4rem !important;
  }
  .p-sm-5 {
    padding: 5rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem !important;
  }
  .p-sm-6 {
    padding: 6rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important;
  }
  .p-sm-7 {
    padding: 7rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important;
  }
  .p-sm-10 {
    padding: 10rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important;
  }
  .m-sm-n1 {
    margin: -1rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important;
  }
  .m-sm-n2 {
    margin: -2rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -2rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -2rem !important;
  }
  .m-sm-n3 {
    margin: -3rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -3rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -3rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -3rem !important;
  }
  .m-sm-n4 {
    margin: -4rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -4rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -4rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -4rem !important;
  }
  .m-sm-n5 {
    margin: -5rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5rem !important;
  }
  .m-sm-n6 {
    margin: -6rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important;
  }
  .m-sm-n7 {
    margin: -7rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important;
  }
  .m-sm-n8 {
    margin: -8rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important;
  }
  .m-sm-n9 {
    margin: -9rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important;
  }
  .m-sm-n10 {
    margin: -10rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 1rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important;
  }
  .m-md-2 {
    margin: 2rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2rem !important;
  }
  .m-md-3 {
    margin: 3rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3rem !important;
  }
  .m-md-4 {
    margin: 4rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4rem !important;
  }
  .m-md-5 {
    margin: 5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem !important;
  }
  .m-md-6 {
    margin: 6rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important;
  }
  .m-md-7 {
    margin: 7rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important;
  }
  .m-md-10 {
    margin: 10rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 1rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important;
  }
  .p-md-2 {
    padding: 2rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2rem !important;
  }
  .p-md-3 {
    padding: 3rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3rem !important;
  }
  .p-md-4 {
    padding: 4rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4rem !important;
  }
  .p-md-5 {
    padding: 5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem !important;
  }
  .p-md-6 {
    padding: 6rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important;
  }
  .p-md-7 {
    padding: 7rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important;
  }
  .p-md-10 {
    padding: 10rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important;
  }
  .m-md-n1 {
    margin: -1rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important;
  }
  .m-md-n2 {
    margin: -2rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -2rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -2rem !important;
  }
  .m-md-n3 {
    margin: -3rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -3rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -3rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -3rem !important;
  }
  .m-md-n4 {
    margin: -4rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -4rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -4rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -4rem !important;
  }
  .m-md-n5 {
    margin: -5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5rem !important;
  }
  .m-md-n6 {
    margin: -6rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important;
  }
  .m-md-n7 {
    margin: -7rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important;
  }
  .m-md-n8 {
    margin: -8rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important;
  }
  .m-md-n9 {
    margin: -9rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important;
  }
  .m-md-n10 {
    margin: -10rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 960px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 1rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important;
  }
  .m-lg-2 {
    margin: 2rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2rem !important;
  }
  .m-lg-3 {
    margin: 3rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3rem !important;
  }
  .m-lg-4 {
    margin: 4rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4rem !important;
  }
  .m-lg-5 {
    margin: 5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem !important;
  }
  .m-lg-6 {
    margin: 6rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important;
  }
  .m-lg-7 {
    margin: 7rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important;
  }
  .m-lg-10 {
    margin: 10rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 1rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important;
  }
  .p-lg-2 {
    padding: 2rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2rem !important;
  }
  .p-lg-3 {
    padding: 3rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3rem !important;
  }
  .p-lg-4 {
    padding: 4rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4rem !important;
  }
  .p-lg-5 {
    padding: 5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem !important;
  }
  .p-lg-6 {
    padding: 6rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important;
  }
  .p-lg-7 {
    padding: 7rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important;
  }
  .p-lg-10 {
    padding: 10rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important;
  }
  .m-lg-n1 {
    margin: -1rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important;
  }
  .m-lg-n2 {
    margin: -2rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -2rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -2rem !important;
  }
  .m-lg-n3 {
    margin: -3rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -3rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -3rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -3rem !important;
  }
  .m-lg-n4 {
    margin: -4rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -4rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -4rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -4rem !important;
  }
  .m-lg-n5 {
    margin: -5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5rem !important;
  }
  .m-lg-n6 {
    margin: -6rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important;
  }
  .m-lg-n7 {
    margin: -7rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important;
  }
  .m-lg-n8 {
    margin: -8rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important;
  }
  .m-lg-n9 {
    margin: -9rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important;
  }
  .m-lg-n10 {
    margin: -10rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 1rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important;
  }
  .m-xl-2 {
    margin: 2rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2rem !important;
  }
  .m-xl-3 {
    margin: 3rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3rem !important;
  }
  .m-xl-4 {
    margin: 4rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4rem !important;
  }
  .m-xl-5 {
    margin: 5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem !important;
  }
  .m-xl-6 {
    margin: 6rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important;
  }
  .m-xl-7 {
    margin: 7rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important;
  }
  .m-xl-10 {
    margin: 10rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 1rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important;
  }
  .p-xl-2 {
    padding: 2rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2rem !important;
  }
  .p-xl-3 {
    padding: 3rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3rem !important;
  }
  .p-xl-4 {
    padding: 4rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4rem !important;
  }
  .p-xl-5 {
    padding: 5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem !important;
  }
  .p-xl-6 {
    padding: 6rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important;
  }
  .p-xl-7 {
    padding: 7rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important;
  }
  .p-xl-10 {
    padding: 10rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important;
  }
  .m-xl-n1 {
    margin: -1rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important;
  }
  .m-xl-n2 {
    margin: -2rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -2rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -2rem !important;
  }
  .m-xl-n3 {
    margin: -3rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -3rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -3rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -3rem !important;
  }
  .m-xl-n4 {
    margin: -4rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -4rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -4rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -4rem !important;
  }
  .m-xl-n5 {
    margin: -5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5rem !important;
  }
  .m-xl-n6 {
    margin: -6rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important;
  }
  .m-xl-n7 {
    margin: -7rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important;
  }
  .m-xl-n8 {
    margin: -8rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important;
  }
  .m-xl-n9 {
    margin: -9rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important;
  }
  .m-xl-n10 {
    margin: -10rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 960px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-1000 {
  font-weight: 1000 !important;
}

.text-white {
  color: #fff !important;
}

.text-primary-100 {
  color: #9ea8d7 !important;
}

a.text-primary-100:hover, a.text-primary-100:focus {
  color: #7a88c8 !important;
}

.text-primary-500 {
  color: #001a96 !important;
}

a.text-primary-500:hover, a.text-primary-500:focus {
  color: #001163 !important;
}

.text-primary-700 {
  color: #283593 !important;
}

a.text-primary-700:hover, a.text-primary-700:focus {
  color: #1d276b !important;
}

.text-primary-a700 {
  color: #002de4 !important;
}

a.text-primary-a700:hover, a.text-primary-a700:focus {
  color: #0023b1 !important;
}

.text-primary-900 {
  color: #001471 !important;
}

a.text-primary-900:hover, a.text-primary-900:focus {
  color: #000b3e !important;
}

.text-secondary-100 {
  color: #f39ed8 !important;
}

a.text-secondary-100:hover, a.text-secondary-100:focus {
  color: #ed71c6 !important;
}

.text-secondary-500 {
  color: #e00099 !important;
}

a.text-secondary-500:hover, a.text-secondary-500:focus {
  color: #ad0076 !important;
}

.text-secondary-700 {
  color: #e633ad !important;
}

a.text-secondary-700:hover, a.text-secondary-700:focus {
  color: #cd1994 !important;
}

.text-secondary-900 {
  color: #c70080 !important;
}

a.text-secondary-900:hover, a.text-secondary-900:focus {
  color: #94005f !important;
}

.text-alternative-danger-red {
  color: #c82914 !important;
}

a.text-alternative-danger-red:hover, a.text-alternative-danger-red:focus {
  color: #9a1f0f !important;
}

.text-alternative-success-green {
  color: #30a04d !important;
}

a.text-alternative-success-green:hover, a.text-alternative-success-green:focus {
  color: #24793a !important;
}

.text-alternative-warning-yellow {
  color: #ffc334 !important;
}

a.text-alternative-warning-yellow:hover, a.text-alternative-warning-yellow:focus {
  color: #ffb401 !important;
}

.text-neutral-100 {
  color: #fff !important;
}

a.text-neutral-100:hover, a.text-neutral-100:focus {
  color: #e6e6e6 !important;
}

.text-neutral-200 {
  color: #f9fafb !important;
}

a.text-neutral-200:hover, a.text-neutral-200:focus {
  color: #dae1e7 !important;
}

.text-neutral-300 {
  color: #f0f1f2 !important;
}

a.text-neutral-300:hover, a.text-neutral-300:focus {
  color: #d5d8da !important;
}

.text-neutral-400 {
  color: #dde0e3 !important;
}

a.text-neutral-400:hover, a.text-neutral-400:focus {
  color: #c1c7cc !important;
}

.text-neutral-500 {
  color: #a7aaad !important;
}

a.text-neutral-500:hover, a.text-neutral-500:focus {
  color: #8d9194 !important;
}

.text-neutral-600 {
  color: #878c91 !important;
}

a.text-neutral-600:hover, a.text-neutral-600:focus {
  color: #6e7377 !important;
}

.text-neutral-700 {
  color: #717379 !important;
}

a.text-neutral-700:hover, a.text-neutral-700:focus {
  color: #585a5f !important;
}

.text-neutral-800 {
  color: #404245 !important;
}

a.text-neutral-800:hover, a.text-neutral-800:focus {
  color: #27292b !important;
}

.text-neutral-900 {
  color: #16192b !important;
}

a.text-neutral-900:hover, a.text-neutral-900:focus {
  color: #050509 !important;
}

.text-body {
  color: #16192b !important;
}

.text-muted {
  color: #878c91 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.divider {
  width: 100%;
  border-top: #dde0e3 1px solid;
}

.divider.divider-light {
  border-top-color: #f0f1f2;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 0.125rem solid #a7aaad;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 960px !important;
  }
  .container {
    min-width: 960px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.125rem solid #16192b;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f0f1f2 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dde0e3;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dde0e3;
  }
}

/*# sourceMappingURL=granite.css.map */