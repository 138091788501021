.spinner {
  display: inline-block;
  animation: rotate 1s linear infinite;

  &.spinner-lg {
    font-size: 8rem;
  }

  &.spinner-md {
    font-size: 4rem;
  }

  &.spinner-sm {
    font-size: 2rem;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
