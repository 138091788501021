// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  visibility: hidden;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();

  &.show {
    visibility: visible;
  }

  .tooltip-arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.tooltip-container {
  position: relative;
}

.tooltip-top {
  padding: $tooltip-arrow-height 0;
  transform: translate(-50%, -103%);

  .tooltip-arrow {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -5%);

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.tooltip-right {
  padding: 0 $tooltip-arrow-height;
  transform: translate(3%, -50%);

  .tooltip-arrow {
    top: 50%;
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    transform: translate(5%, -50%);

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.tooltip-bottom {
  padding: $tooltip-arrow-height 0;
  transform: translate(-50%, 3%);

  .tooltip-arrow {
    top: 0;
    left: 50%;
    transform: translate(-50%, 5%);

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.tooltip-left {
  padding: 0 $tooltip-arrow-height;
  transform: translate(-103%, -50%);

  .tooltip-arrow {
    top: 50%;
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    transform: translate(-5%, -50%);

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  line-height: $tooltip-line-height;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}

// Compound tooltip
.tooltip.tooltip-compound {
  .tooltip-arrow {
    display: none;
  }
}

// Compound tooltip (light)
.tooltip.tooltip-compound-light {
  .tooltip-inner {
    color: $gray-900;
    background-color: $gray-100;
    box-shadow: $box-shadow-all;
  }

  .tooltip-arrow {
    display: none;
  }
}

// Error tooltip
.tooltip.tooltip-error {
  &.tooltip-top .tooltip-arrow::before {
    border-top-color: $danger-red;
  }

  &.tooltip-right .tooltip-arrow::before {
    border-right-color: $danger-red;
  }

  &.tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $danger-red;
  }

  &.tooltip-left .tooltip-arrow::before {
    border-left-color: $danger-red;
  }

  .tooltip-inner {
    background-color: $danger-red;
  }
}

// Success tooltip
.tooltip.tooltip-success {
  &.tooltip-top .tooltip-arrow::before {
    border-top-color: $success-green;
  }

  &.tooltip-right .tooltip-arrow::before {
    border-right-color: $success-green;
  }

  &.tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $success-green;
  }

  &.tooltip-left .tooltip-arrow::before {
    border-left-color: $success-green;
  }

  .tooltip-inner {
    background-color: $success-green;
  }
}

// Warning tooltip
.tooltip.tooltip-warning {
  &.tooltip-top .tooltip-arrow::before {
    border-top-color: $warning-yellow;
  }

  &.tooltip-right .tooltip-arrow::before {
    border-right-color: $warning-yellow;
  }

  &.tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $warning-yellow;
  }

  &.tooltip-left .tooltip-arrow::before {
    border-left-color: $warning-yellow;
  }

  .tooltip-inner {
    color: $gray-900;
    background-color: $warning-yellow;
  }
}
