.tags {
  display: inline-flex;
  align-items: center;
  padding: .5rem 1rem;
  color: $gray-900;
  background: $gray-400;
  @include border-radius($border-radius);

  i {
    margin-left: 1rem;
    font-size: 2rem;
    vertical-align: middle;
  }

  a {
    color: $gray-900;

    &:visited {
      color: $gray-900;
    }

    &:hover,
    &:active,
    &:focus {
      color: $course-hero-blue-500;
    }
  }

  &.tags-white {
    background: $white;
    border: $gray-900 1px solid;
  }

  &.tags-plain {
    background: transparent;
  }
}

a.tags {
  color: $gray-900;

  &:visited {
    color: $gray-900;
  }

  &:hover {
    color: $white;
    background: $gray-900;
  }

  &:active {
    color: $white;
  }

  &:focus {
    color: $white;
    background: $course-hero-blue-500;
  }
}
